import { useQuery } from "@apollo/client";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "components/CustomToolbar";
import { ADMIN_SEARCH_MEMBERS } from "data/queries/community";
import { format } from "date-fns";

const { Typography, Stack, Box } = require("@mui/material")

const UserTab = ({ userCount }) => {
  const { data, loading } = useQuery(ADMIN_SEARCH_MEMBERS);
  const rows = (data?.community?.members?.edges || []).map(({node, joinDate}) => ({
    ...node,
    joinDate,
  }));
  const columns = [
    // {
    //   field: 'username',
    //   headerName: 'View',
    //   width: 250,
    //   renderCell: ({ row }) => {
    //     return <Link
    //       to={`/e/${row.username}`}
    //     >
    //       <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    //         <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2ZM11 2V0H18V7H16V3.41L6.17 13.24L4.76 11.83L14.59 2H11Z" fill="black" fill-opacity="0.54"/>
    //       </svg>
    //   </Link>
    //   },
    // },
    {
      field: 'displayName',
      headerName: 'Name',
      width: 300,
      renderCell: ({ row }) => {
        return <Box>{`${row.displayName}`}</Box>
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      renderCell: ({ row }) => {
        return <a
          href={`mailto:${row.email}`}
        >{`${row.email}`}</a>
      },
    },
    {
      field: 'joinDate',
      headerName: 'Date Joined',
      width: 200,
      renderCell: ({ row }) => {
        return <Box>{`${format(new Date(row.joinDate), 'MM/dd/yyyy')}`}</Box>
      },
    },
    {
      field: 'interests',
      headerName: 'Interests',
      width: 300,
      renderCell: ({ row }) => {
        return <Box>{`${(row.interests || []).join(', ')}`}</Box>
      },
    },
    {
      field: 'events',
      headerName: 'Events',
      width: 300,
      renderCell: ({ row }) => {
        return <Box>{`${(row.events.edges || [])
          .map(e => e.node.title).join(', ')}`}</Box>
      },
    }
  ];

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
        <Typography variant="h2">Members</Typography>
        <Typography sx={{ mb: 1 }}>
          Total Members <b>{userCount}</b>
        </Typography>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading || !data}
        slots={{ toolbar: CustomToolbar }}
      />
    </>
  )
}

export default UserTab;
