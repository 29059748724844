import { checkboxClasses } from "@mui/material/Checkbox";

export const lightTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1080,
      xl: 1920,
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#f8f8f8",
    },
    primary: {
      main: "#D9D9D9",
      light: "#C4C4C4",
      dark: "#000000",
      contrastText: "#000000",
    },
    notify: {
      main: "#F5D561",
      info: "rgba(245, 213, 97, 0.3)",
    },
    mint: {
      main: "#3FF8AA",
    },
    lime: {
      main: "#A0CB25",
    },
    sunshine: {
      main: "#E4DB05",
    },
    golden: {
      main: "#DF8B0E",
    },
    orange: {
      main: "#EF5D2F",
    },
    coral: {
      main: "#F0466E",
    },
    periwinkle: {
      main: "#C34AFB",
    },
    lagoon: {
      main: "#6B9DFA",
    },
    sky: {
      main: "#58D6E8",
    },
    simple: {
      main: "#F8F8F8"
    },
    overlay: {
      main: "rgba(0, 0, 0, 0.4)",
      secondary: "#f8f8f8",
      contrast: "rgba(255, 255, 255, 0.50)",
      cta: "#D9D9D9",
      info: "rgba(0, 0, 0, 0.45)",
    },
    svg: {
      main: "black",
      secondary: "white",
    },
    message: {
      background: "rgba(255, 255, 255, 0.85)",
      button: "rgba(0, 0, 0, 0.05)",
    },
    highlight: {
      main: "rgba(245, 213, 97, 0.3)",
    },
    transparentColors: {
      light: {
        twentyFive: 'rgba(255, 255, 255, 0.25)',
        forty: 'rgba(255, 255, 255, 0.40)',
        fifty: 'rgba(255, 255, 255, 0.50)',
        sixty: 'rgba(255, 255, 255, 0.6)',
        seventy: 'rgba(255, 255, 255, 0.7)',
        seventyFive: 'rgba(255, 255, 255, 0.75)',
        eighty: 'rgba(255, 255, 255, 0.8)',
        ninety: 'rgba(255, 255, 255, 0.9)',
      },
      dark: {
        five: 'rgba(0, 0, 0, 0.05)',
        ten: 'rgba(0, 0, 0, 0.10)',
        twenty: 'rgba(0, 0, 0, 0.20)',
        twentyFive: 'rgba(0, 0, 0, 0.25)',
        thirty: 'rgba(0, 0, 0, 0.30)',
        thirtyFive: 'rgba(0, 0, 0, 0.35)',
        fortyFive: 'rgba(0, 0, 0, 0.45)',
        fifty: 'rgba(0, 0, 0, 0.50)',
        seventy: 'rgba(0, 0, 0, 0.70)',
        eighty: 'rgba(0, 0, 0, 0.80)',
        ninety: 'rgba(0, 0, 0, 0.90)'
      }
    },
    // secondary: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // error: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // warning: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // info: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // success: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    grey: {
      main: "#3D3D3D",
      // light: "#000",
      // dark: "#000",
      // contrastText: "#fff",
    },
    text: {
      primary: "#000000",
      secondary: "#818181",
      disabled: "#3D3D3D",
    },
    // action: {
    //     active: "",
    //     hover: "",
    //     hoverOpacity: "",
    //     disabled: "",
    //     disabledBackground: "",
    //     disabledOpacity: "",
    //     focus: "",
    //     focusOpacity: "",
    // }
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: ["GT-America-Medium", "sans-serif"].join(","),
    h1: {
      fontFamily: "GT-America-Medium",
      color: "rgba(255, 255, 255, 0.75)",
      fontSize: 20,
      fontWeight: 500,
    },
    h2: {
      fontFamily: "GT-America-Medium",
      color: "#000000",
      fontSize: 28,
      fontWeight: 500,
    },
    h3: {
      fontFamily: "GT-America-Medium",
      color: "#000000",
      fontSize: 18,
      fontWeight: 500,
    },
    h4: {
      fontFamily: "GT-America-Medium",
      color: "#000000",
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      fontFamily: "GT-America-Medium",
      color: "#000000",
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontFamily: "GT-America-Medium",
      color: "#000000",
      fontSize: 12,
      fontWeight: 500,
    },
    body1: {
      fontFamily: "GT-America-Regular",
      fontSize: 16,
      fontWeight: 400,
      color: "rgba(29, 29, 29, 1)",
    },
    body2: {
      fontFamily: "GT-America-Regular",
      fontSize: 12,
      fontWeight: 400,
      color: "#000000",
    },
    subtitle1: {
      fontFamily: "GT-America-Regular",
      fontSize: 14,
      fontWeight: 400,
      color: "#000000",
      lineHeight: "17px",
    },
    button: {
      fontFamily: "GT-America-Medium",
    },
  },

  components: {
    // https://mui.com/material-ui/customization/theme-components/#global-style-overrides
    // component overrides here
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            background: "#000",
            textTransform: "none",
            padding: "20px 50px",
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '14px',
            borderRadius: '27px',
            width: 'fit-content',
            color: 'white',
            textAlign: 'center',
            boxShadow: 'none',
            "&:disabled": {
              opacity: 0.3,
            },
          },
        },
        {
          props: { variant: "regular" },
          style: {
            background: "white",
            textTransform: "none",
            padding: "20px 30px",
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '14px',
            width: 'fit-content',
            color: 'rgba(0, 0, 0, 0.7)',
            textAlign: 'center',
            boxShadow: 'none',
            border: '1px solid #E6E6E6',
            borderRadius: '10px',
            "&:disabled": {
              opacity: 0.3,
              background: "rgba(255, 255, 255, 0.7)",
            },
            "&:hover": {
              background: "rgba(255, 255, 255, 0.2)",
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderWidth: '1px',
            borderColor: "rgba(255, 255, 255, 1)",
            background: 'transparent',
            textTransform: 'none',
            padding: '10px 20px',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '14px',
            borderRadius: '6px',
            textAlign: 'center',
            width: 'fit-content',
            color: 'rgba(255, 255, 255, 1)',
            "&:disabled": {
              opacity: 0.3,
            },
            "&:hover": {
              borderColor: 'rgba(255, 255, 255, 0.4)',
              boxShadow: 'none',
              borderWidth: '1px',
            }
          }
        },
        {
          props: { variant: "tertiary" },
          style: {
            textTransform: "none",
            background: "#E6E6E6",
            padding: "3px 9px",
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: '14px',
            borderRadius: '12px',
            width: 'fit-content',
            textAlign: 'center',
            color: '#666',
            "&:disabled": {
              opacity: 0.3,
            },
            "&:hover": {
              background: "rgba(0, 0, 0, 0.2)",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          // padding: "15px 40px",
          // height: 60,
          // maxWidth: 350,
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          },
        },
        {
          props: { variant: "tertiary" },
          style: {
            color: "rgba(255, 255, 255, 1)",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            padding: "12px 16px",
            borderRadius: "8px",
            "&:disabled": {
              opacity: 0.3,
              backgroundColor: "rgba(0, 0, 0, 0.25)",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          },
        },
      ],
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 25,
          paddingRight: 25,
          marginBottom: 20,
          maxWidth: 1320,
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: "#3D3D3D",
          borderRadius: 20,
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            background: "transparent",
          },
        },
      ],
      styleOverrides: {
        root: {
          background: "rgba(255, 255, 255, 0.75)",
          color: "rgba(0, 0, 0, 0.45) !important",
          borderRadius: 10,
          border: 0,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          fontSize: '14px',
          color: 'rgb(0, 0, 0, 0.50)',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          background: "rgba(255, 255, 255, 0.75)",
          borderRadius: 10,
          color: "rgba(0, 0, 0, 0.45) !important",
          border: "1px solid #999999"
        },
      },
    },

    MuiOutlinedInput: {
      border: "1px solid #999999",
      borderRadius: 10,
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.45) !important",
        },
      },
    },

    // MuiSelect: {
    //   variants: [
    //     {
    //       props: { variant: "filled" },
    //       style: {
    //         borderRadius: "8px",
    //         backgroundColor: "black !important",
    //         textTransform: "none",
    //         width: "fit-content",
    //         color: "white",
    //         textAlign: "center",
    //         ".MuiSelect-select": {
    //           paddingTop: "8px",
    //         },
    //         ".MuiSelect-icon": {
    //           color: "white",
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "standard" },
    //       style: {
    //         borderRadius: "8px",
    //         backgroundColor: "black",
    //         textTransform: "none",
    //         width: "fit-content",
    //         color: "white",
    //         textAlign: "center",
    //         paddingTop: "8px",
    //       },
    //     },
    //     {
    //       props: { variant: "outlined" },
    //       style: {
    //         background: "rgba(255, 255, 255, 0.75)",
    //         borderRadius: 10,
    //         border: 0,
    //         color: "rgba(0, 0, 0, 0.45) !important",
    //         width: "360px",
    //         maxWidth: "100%",
    //       },
    //     },
    //   ],
    // },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.2) !important",
          },
          "&:selected": {
            backgroundColor: "rgba(0, 0, 0, 0.2) !important",
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 70,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 70,
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 30,
        },
      },
    },

    MuiDrawer: {
      variants: [
        {
          props: { variant: "signup" },
          style: {
            ".MuiDrawer-paper": {
              borderTopRightRadius: 30,
              borderTopLeftRadius: 30,
            },
          },
        },
      ],
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#7d7f80",
          [`&.${checkboxClasses.checked}`]: {
            color: "#7d7f80",
          },
        },
      },
    },
  },
};

export const darkTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1080,
      xl: 1920,
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#1E1E1E",
    },
    primary: {
      main: "#D9D9D9",
      light: "#222222",
      dark: "#000",
      contrastText: "#fff",
    },
    notify: {
      main: "#F5D561",
      info: "rgba(245, 213, 97, 0.3)",
    },
    mint: {
      main: "#3FF8AA",
    },
    lime: {
      main: "#A0CB25",
    },
    sunshine: {
      main: "#E4DB05",
    },
    golden: {
      main: "#DF8B0E",
    },
    orange: {
      main: "#EF5D2F",
    },
    coral: {
      main: "#F0466E",
    },
    periwinkle: {
      main: "#C34AFB",
    },
    lagoon: {
      main: "#6B9DFA",
    },
    sky: {
      main: "#58D6E8",
    },
    overlay: {
      main: "rgba(0, 0, 0, 0.4)",
      secondary: "rgba(0, 0, 0, 0.70)",
      contrast: "rgba(0, 0, 0, 0.50)",
      cta: "rgba(160, 160, 160, 0.7)",
    },
    svg: {
      main: "white",
      secondary: "black",
    },
    message: {
      background: "rgba(0, 0, 0, 0.85)",
      button: "rgba(0, 0, 0, 1)",
    },
    highlight: {
      main: "rgba(245, 213, 97, 0.3)",
    },
    // secondary: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // error: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // warning: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // info: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // success: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    grey: {
      main: "#3D3D3D",
      // light: "#000",
      // dark: "#000",
      // contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "#999999",
      disabled: "#3D3D3D",
    },
    // action: {
    //     active: "",
    //     hover: "",
    //     hoverOpacity: "",
    //     disabled: "",
    //     disabledBackground: "",
    //     disabledOpacity: "",
    //     focus: "",
    //     focusOpacity: "",
    // }
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: ["GT-America-Medium", "sans-serif"].join(","),
    h1: {
      fontFamily: "GT-America-Medium",
      color: "rgba(255, 255, 255, 0.75)",
      fontSize: 20,
      fontWeight: 500,
    },
    h2: {
      fontFamily: "GT-America-Medium",
      color: "rgba(0, 0, 0, 0.7)",
      fontSize: 28,
      fontWeight: 500,
    },
    h3: {
      fontFamily: "GT-America-Medium",
      color: "rgba(0, 0, 0, 0.7)",
      fontSize: 18,
      fontWeight: 500,
    },
    h4: {
      fontFamily: "GT-America-Medium",
      color: "rgba(0, 0, 0, 0.7)",
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      fontFamily: "GT-America-Medium",
      color: "rgba(0, 0, 0, 0.7)",
      fontSize: 14,
      fontWeight: 500,
    },
    body1: {
      fontFamily: "GT-America-Regular",
      fontSize: 16,
      fontWeight: 400,
      color: "rgba(29, 29, 29, 1)",
    },
    body2: {
      fontFamily: "GT-America-Regular",
      fontSize: 12,
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.7)",
    },
    subtitle1: {
      fontFamily: "GT-America-Regular",
      fontSize: 14,
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.7)",
      lineHeight: "17px",
    },
    button: {
      fontFamily: "GT-America-Medium",
    },
  },

  components: {
    // https://mui.com/material-ui/customization/theme-components/#global-style-overrides
    // component overrides here
    // MuiButton: {
    //   variants: [
    //     {
    //       // NOTE: this is the default button
    //       props: { variant: "text" },
    //       style: {
    //         borderRadius: "8px",
    //         color: "#fff",
    //         "&:hover": {},
    //         "&:disabled": {
    //           color: "#999DA0",
    //         },
    //       },
    //     },
    //     {
    //       // NOTE: this is the default button
    //       props: { variant: "action" },
    //       style: {
    //         borderRadius: "4px",
    //         padding: "5px 15px",
    //         backgroundColor: "rgba(255, 255, 255, 0.15)",
    //         textTransform: "none",
    //         "&:hover": {
    //           backgroundColor: "#999DA0",
    //         },
    //         "&:disabled": {
    //           color: "#999DA0",
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "outlined" },
    //       style: {
    //         borderWidth: 1,
    //         borderColor: "#fff",
    //         background: "transparent",
    //         color: "#fff",
    //         borderRadius: "8px",
    //         "&:hover": {
    //           backgroundColor: "#999DA0",
    //         },
    //         "&:disabled": {
    //           borderWidth: 1,
    //           borderColor: "#fff",
    //           color: "#666666",
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "contained" },
    //       style: {
    //         borderRadius: "8px",
    //         backgroundColor: "#FFFFFF",
    //         width: "fit-content",
    //         color: "black",
    //         textAlign: "center",
    //         "&:hover": {
    //           backgroundColor: "#999DA0",
    //         },
    //         "&:disabled": {
    //           backgroundColor: "#666666",
    //           color: "#121212",
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "message" },
    //       style: {
    //         background: "black",
    //         textTransform: "none",
    //         padding: "3px 7px",
    //         fontWeight: 400,
    //         borderRadius: "4px",
    //         "&:disabled": {
    //           opacity: 0.2,
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "secondary" },
    //       style: {
    //         background: "rgba(0, 0, 0, 0.40)",
    //         textTransform: "none",
    //         padding: "8px 15px",
    //         fontWeight: 400,
    //         fontSize: "14px",
    //         borderRadius: "6px",
    //         width: "fit-content",
    //         color: "rgba(255, 255, 255, 1)",
    //         "&:disabled": {
    //           opacity: 0.2,
    //         },
    //       },
    //     },
    //   ],
    //   styleOverrides: {
    //     root: {
    //       padding: "15px 40px",
    //       // height: 60,
    //       // maxWidth: 350,
    //     },
    //   },
    // },

    MuiIconButton: {
      variants: [
        {
          props: { variant: "tertiary" },
          style: {
            background: "rgba(0, 0, 0, 0.40)",
            color: "rgba(255, 255, 255, 1)",
            "&:disabled": {
              opacity: 0.3,
            },
            "&:hover": {
              background: "rgba(0, 0, 0, 0.2)",
            },
          },
        },
      ],
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 25,
          paddingRight: 25,
          marginBottom: 20,
          maxWidth: 1320,
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: "#3D3D3D",
          borderRadius: 20,
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 70,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 70,
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 30,
        },
      },
    },

    MuiDrawer: {
      variants: [
        {
          props: { variant: "signup" },
          style: {
            ".MuiDrawer-paper": {
              borderTopRightRadius: 30,
              borderTopLeftRadius: 30,
            },
          },
        },
      ],
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "white",
          [`&.${checkboxClasses.checked}`]: {
            color: "white",
          },
        },
      },
    },
  },
};
