import { gql } from "@apollo/client";

export const CREATE_CHANNEL = gql`
  mutation CreateChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      channel {
        id
        community {
          id
          channels {
            edges {
              node {
                id
                slug
                section
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CHANNEL = gql`
  mutation DeleteChannel($input: DeleteChannelInput!) {
    deleteChannel(input: $input) {
      success
    }
  }
`;

export const REACT_TO_POST = gql`
  mutation ReactToPost($input: ReactToPostInput!) {
    reactToPost(input: $input) {
      reaction {
        id
      }
    }
  }
`;

export const NOTIFY_COMMUNITY_MEMBERS = gql`
mutation NotifyCommunityMembers($input: NotifyCommunityMembersInput!) {
  notifyCommunityMembers(input: $input) {
    community {
      id
    }
  }
}
`;

export const NOTIFY_CHANNEL_MEMBERS = gql`
mutation NotifyChannelMembers($input: NotifyChannelMembersInput!) {
  notifyChannelMembers(input: $input) {
    channel {
      id
    }
  }
}
`;

export const ADD_CHANNEL_MEMBERS = gql`
  mutation($input: AddChannelMembersInput!) {
    addChannelMembers(input: $input) {
      channel {
        id
        members {
          total
          edges {
            node {
              id
              firstName
              lastName
              username
              avatar { id url }
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_CHANNEL_MEMBERS = gql`
  mutation($input: RemoveChannelMembersInput!) {
    removeChannelMembers(input: $input) {
      channel {
        id
        members {
          total
          edges {
            node {
              id
              firstName
              lastName
              displayName
              username
              avatar { id url }
            }
          }
        }
      }
    }
  }
`;

export const REPORT_POST = gql`
  mutation ReportPost($input: ReportPostInput!) {
    reportPost(input: $input) {
      success
    }
  }
`;

export const UPDATE_CHANNEL_MEMBERSHIP = gql`
  mutation($input: UpdateChannelMembershipInput!) {
    updateChannelMembership(input: $input) {
      channel {
        id
      }
    }
  }
`;

export const CONVERSATION_TOKEN = gql`
  query ConversationToken($input: ConversationTokenInput) {
    conversationToken(input: $input)
  }
`;

export const JOIN_CHANNEL = gql`
  mutation($input: JoinChannelInput!) {
    joinChannel(input: $input) {
      channel {
        id
      }
    }
  }
`;

export const EDIT_CHANNEL = gql`
  mutation($input: EditChannelInput!) {
    editChannel(input: $input) {
      channel {
        emoji
        name
        id
        slug
        description
        isPrivate
        isProtected
      }
    }
  }
`