import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Grid } from '@mui/material'

export default applyTo(({ children }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: 'calc(100dvh - 70px)' }}
    >
      <Grid item xs={3}>
        {children}
      </Grid>
    </Grid>
  );
}, pipe(
  propTypes({
    children: PropTypes.node,
  }),
  defaultProps({
    children: null,
  }),
  displayName('FlexCenter'),
  memo,
));
