import { useContext, useEffect } from 'react';
import { AppContext } from 'context';
import config from 'config';

export default function Login() {
    const { logout } = useContext(AppContext);

    useEffect(() => {
        logout({
            logoutParams: {
                returnTo: `${window.location.protocol}//${config.BASE_URL}`,
            },
        });
    })
    return null;
}
