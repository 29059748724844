import React, { createContext, useEffect, useState } from "react";
import { Client } from '@twilio/conversations';
import PropTypes from 'prop-types';
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { Cloudinary } from "@cloudinary/url-gen";
import { path } from "ramda";
import { CONVERSATION_TOKEN } from "data/mutations/channel";
import { NavbarProvider } from "./NavBarContext";
import ErrorModal from "components/ErrorModal";
import useCustomAuth0 from "hooks/useCustomAuth0";

const AppContext = createContext();

const HEX_CODE_TO_FEATURED = {
  '#3FF8AA': 'mint',
  '#A0CB25': 'lime',
  '#E4DB05': 'sunshine',
  '#DF8B0E': 'golden',
  '#EF5D2F': 'orange',
  '#F0466E': 'coral',
  '#C34AFB': 'periwinkle',
  '#6B9DFA': 'lagoon',
  '#58D6E8': 'sky',
  '#F5D561': 'notify'
};

const AppContextProvider = ({ theme, setLightTheme, setDarkTheme, children }) => {
  const [featuredColor, setFeaturedColor] = useState('notify');
  const [featuredHexCode, setFeaturedHexCode] = useState('#F5D561');
  const [chat, setChat] = useState();
  const [error, setError] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const {
    logout: auth0Logout,
    isAuthenticated,
    user
  } = useCustomAuth0();
  const client = useApolloClient();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [fetchToken] = useLazyQuery(CONVERSATION_TOKEN, {
    fetchPolicy: 'network-only'
  });
  const [clientIdentity, setClientIdentity] = useState(user?.id);

  const openNav = () => setIsNavOpen(true);
  const closeNav = () => setIsNavOpen(false);

  const cloudinary = new Cloudinary({
    cloud: {
      cloudName: 'lets-rally'
    },
    secure: true
  });

  const updateFeaturedColor = (hexCode) => {
    const color = HEX_CODE_TO_FEATURED[hexCode];
    setFeaturedColor(color || 'mint');
    setFeaturedHexCode(color ? hexCode : '#3FF8AA');
  }

  useEffect(() => {
    const setupChatClient = async () => {
      if (!isAuthenticated || chat) return;
      console.log('setting up chat client');
      const token = await fetchToken()
        .then(path(['data', 'conversationToken']));
      const chatClient = new Client(token);

      chatClient.on('stateChanged', (state) => {
        console.log('chat state', state);
        if (state === 'initialized') {
          setChat(chatClient);
          return;
        }
        setError('Unable to load chat. Please reload.')
      });
      chatClient.on('tokenAboutToExpire', () => {
        setChat();
        setupChatClient();
      });
    }

    setupChatClient();
  }, [isAuthenticated, fetchToken, chat]);

  const changeClientIdentity = async (identity) => {
    const token = await fetchToken({
      variables: {
        input: {
          identity
        }
      }
    })
      .then(path(['data', 'conversationToken']))
    await chat.shutdown();
    const newClient = new Client(token);

    return new Promise((resolve, reject) => {
      newClient.on('stateChanged', (state) => {
        console.log('chat state', state);
        if (state === 'initialized') {
          setChat(newClient);
          setClientIdentity(identity);
          resolve(newClient);
          return;
        }
        setError('Unable to load chat. Please reload.');
        reject('Unable to load chat. Please reload.');
      });
    })
  }

  const logout = (options = {}) => {
    setChat(null);
    auth0Logout(options);
    window.localStorage.removeItem('token');
    client.resetStore();
  }

  return (
    <NavbarProvider>
      <AppContext.Provider
        value={{
          theme,
          setLightTheme,
          setDarkTheme,
          chat,
          cloudinary,
          logout,
          onError: setError,
          featuredColor,
          featuredHexCode,
          updateFeaturedColor,
          clientIdentity,
          changeClientIdentity,
          isNavOpen,
          openNav,
          closeNav,
          showAlert,
          setShowAlert
        }}
      >
        {children}
        <ErrorModal
          error={error}
          onClose={() => setError()}
        />
      </AppContext.Provider>
    </NavbarProvider>
  )
}

AppContextProvider.propTypes = {
  children: PropTypes.node,
}

export { AppContext, AppContextProvider }