import packageInfo from '../package.json';

// NOTE: env vars cannot be destructured out of process.env in nextjs due to a webpack plugin it uses
// https://github.com/vercel/next.js/issues/15574

const REACT_APP_INFRA_NODE_ENV = process.env.REACT_APP_INFRA_NODE_ENV;
const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const REACT_APP_FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const DEFAULT_MANIFEST = {
  "short_name": "Rally",
  "name": "Rally",
  "icons": [
    {
      "src": "favicon.ico",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    },
    {
      "src": "logo.png",
      "type": "image/png",
      "sizes": "256x256 512x512"
    }
  ],
  "start_url": ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
}

// required env vars
const envOkay = [
  // general
  REACT_APP_INFRA_NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_BASE_URL,
  // firebase
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  // auth0
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  // google 
  REACT_APP_GOOGLE_API_KEY
].every(env => env);
if (!envOkay) console.warn('One or more env vars are missing. Please check your .env.local file in the project root.');

const config = {
  // available server-side only
  FIREBASE_PROJECT_ID: REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_APP_ID: REACT_APP_FIREBASE_APP_ID,
  FIREBASE_API_KEY: REACT_APP_FIREBASE_API_KEY,
  FIREBASE_MEASUREMENT_ID: REACT_APP_FIREBASE_MEASUREMENT_ID,

  // available client-side
  API_HOST: REACT_APP_API_HOST,
  NODE_ENV: REACT_APP_INFRA_NODE_ENV,
  BASE_URL: REACT_APP_BASE_URL,
  VERSION: packageInfo.version,
  AUTH0_DOMAIN: REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: REACT_APP_AUTH0_AUDIENCE,

  GOOGLE_API_KEY: REACT_APP_GOOGLE_API_KEY,

  DEFAULT_MANIFEST
};

export default config;