import { useEffect } from 'react';
import useCustomAuth0 from 'hooks/useCustomAuth0';

export default function Login() {
    const { loginWithRedirect } = useCustomAuth0();
    useEffect(() => {
        loginWithRedirect();
    })
    return null;
}
