import { memo } from 'react';
import { pipe, applyTo } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Box } from '@mui/system';
import { useQuery } from '@apollo/client';
import { PLATFORM_STATS } from 'data/queries/community';
// import CommunityTab from './CommunityTab';
// import EventTab from './EventTab';
import UserTab from './UserTab';

export default applyTo(() => {
  const { data } = useQuery(PLATFORM_STATS);
  // const [value, setValue] = useState(0);

  return (
    <Box sx={{ p: 3, height: 'calc(100dvh - 60px)' }}>
      {/* <Box sx={{ mb: 2 }}>
        <Tabs value={value} onChange={(e, num) => setValue(num)}>
          <Tab label="Members" />
          <Tab label="Communities" />
          <Tab label="Events" />
        </Tabs>
      </Box> */}
      <UserTab
        userCount={data?.platform?.stats?.userCount}
      />
      {/* {value === 1 && <CommunityTab
        communityCount={data?.platform?.stats?.communityCount}
      />}
      {value === 2 && (
        <EventTab
          eventCount={data?.platform?.stats?.eventCount}
        />
      )} */}
    </Box>
  )
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('AdminHome'),
  memo,
));
