import { useQuery } from "@apollo/client";
import { Box, Button, Typography, useTheme } from "@mui/material";
import BottomDrawer from "components/BottomDrawer";
import ButtonMailto from "components/ButtonMailTo";
import { WHO_AM_I } from "data/queries/users";
import useCustomAuth0 from "hooks/useCustomAuth0";

export default function ErrorModal({ error, onClose }) {
  const { isAuthenticated } = useCustomAuth0();
  const { data } = useQuery(WHO_AM_I, {
    skip: !isAuthenticated
  });
  const theme = useTheme();

  return (
    <BottomDrawer
      isOpen={!!error}
      onClose={onClose}
      title='Oops, something went wrong'
    >
      <Box sx={{
        backgroundColor: theme.palette.transparentColors.dark.ten,
        borderRadius: '6px',
        padding: '10px',
        my: '20px'
      }}>
        <Typography>{error}</Typography>
      </Box>
      <Button
        variant='contained'
      >
        <ButtonMailto
          mailto='support@letsrally.io'
          label={'Report Bug'}
          subject={encodeURIComponent('Report a bug')}
          body={encodeURIComponent(`Thanks for reporting! It makes a huge difference and we review every report that is sent.

                Your details:
                - Member who reported: ${data?.whoami?.username}
                - Device and browser: ${navigator.userAgent}
                - Error message: ${error}

                Any other details you would like to add?

                Feel free to include screenshots or a screen recording.

                Thanks again!

                The Rally Team
              `)}
        />
      </Button>
    </BottomDrawer>
  )
}