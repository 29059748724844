import { jwtDecode } from "jwt-decode";
import useLocalStorage from './useLocalStorage';
import { useAuth0 } from "@auth0/auth0-react";

const getUserFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    const expiresAccessMs = (decoded?.exp || 0) * 1000;
    const now = Date.now();
    if (now > expiresAccessMs) {
      return null;
    }
    return {
      id: decoded.sub,
    };
  } catch (error) {
    return null;
  }
}

const isValid = (token) => {
  try {
    const decoded = jwtDecode(token);
    const expiresAccessMs = (decoded?.exp || 0) * 1000;
    const now = Date.now();
    return now < expiresAccessMs;
  } catch (error) {
    return null;
  }
}

const useCustomAuth0 = (options) => {
  const auth0 = useAuth0(options);
  const [token] = useLocalStorage('app_token');
  const isReactNative = window.IS_REACT_NATIVE;

  const getAccessTokenSilently = async (options) => {
    if (isReactNative && isValid(token)) return token;
    return auth0.getAccessTokenSilently(options);
  }

  const logout = (options) => {
    if (isReactNative) {
      return window.localStorage.removeItem('app_token');
    }
    return auth0.logout(options);
  }

  return {
    ...auth0,
    getAccessTokenSilently,
    logout,
    isAuthenticated: auth0.isAuthenticated || (isReactNative && isValid(token)),
    user: (isReactNative && !!token)
      ? getUserFromToken(token)
      : auth0.user,
  }
}

export default useCustomAuth0;