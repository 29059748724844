import Progress from 'components/Progress';
import React, { useCallback, useEffect } from 'react';
import config from 'config';
import { Navigate } from "react-router-dom";
import useCustomAuth0 from 'hooks/useCustomAuth0';

const isAdmin = (user) => {
  const roles = user[`${config.API_HOST}/roles`] || [];
  return roles.includes('ADMIN');
}

export default function AdminRoute({ component }) {
  const { isLoading: isAuthenticating, isAuthenticated, user, loginWithRedirect } = useCustomAuth0();

  const handleLogin = useCallback(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'login',
      },
      appState: {
        returnTo: window.location.href,
      },
    })
  }, [loginWithRedirect]);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      handleLogin();
    }
  }, [isAuthenticating, isAuthenticated, handleLogin]);

  if (!isAuthenticating && isAuthenticated && !isAdmin(user)) {
    return <Navigate to="/" replace />;
  }

  if (isAuthenticating || !isAuthenticated || (isAuthenticated && user && user.org_id)) {
    return <Progress />;
  }

  return component;
};