import { gql } from "@apollo/client";

export const SEARCH_USERS = gql`
  query SearchUsers($input: SearchUsersInput!) {
    searchUsers(input: $input) {
      edges {
        node {
          id
          firstName
          lastName
          username
          displayName
          avatar {
            id
            url
          }
        }
      }
    }
  }
`;

export const USERS = gql`
  query Users($ids: [ID!]!) {
    nodes(ids: $ids) {
      ...on User {
        __typename
        id
        displayName
        slug: username
        avatar { 
          id 
          url(input: {
            transformations: [{
              aspect_ratio: "1:1"
              crop: "fill",
            }]
          })
        }
      }
      ...on Community {
        __typename
        id
        slug
        displayName: title
        avatar: logo {
          id
          url(input: {
            transformations: [{
              aspect_ratio: "1:1"
              crop: "fill",
            }]
          })
        }
      }
    }
  }
`;

export const WHO_AM_I = gql`
query Whoami {
    whoami {
      id
      phoneNumber
      email
      displayName
      firstName
      lastName
      username
      bio
      avatar {
        id
        url(input: {
          transformations: [{
            aspect_ratio: "1:1"
            crop: "fill",
          }]
        })
      }
      socialLinks {
        instagram
      }
      address {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      pronouns
      gender
      shirtSize
      birthday
      skillLevel
      notifications {
        email {
          directMessages
          channelMessages
          directMention
          newEvents
          eventReminders
          communityAnnouncements
          groupMessageSummary
          communityUpdate
        }
      }
      communities(input: { first: 30 }) {
        edges {
          memberNumber
          isMember
          node {
            id
            title
            slug
            logo { 
              id
              url(input: {
                transformations: [{
                  aspect_ratio: "1:1"
                  crop: "fill",
                  gravity: "auto"
                }]
              }) 
            }
          }
        }
      }
      channels {
        edges {
          node {
            id
            slug
            name
            unreadMessagesCount
            members {
              edges {
                node {
                  id
                  displayName
                  avatar { id url }
                }
              }
            }
          }
        }
      }
      inventory {
        edges {
          node {
            ...on Vehicle {
              id
              name
              make
              model
              type
              category
              year
              photo {
                id
                url(input: {
                  transformations: [{
                    aspect_ratio: "1:1"
                    crop: "fill",
                    gravity: "auto"
                  }]
                })
              }
            }
          }
        }
      }
    }
  }
`;


export const USER = gql`
query User($username: String!) {
    user(username: $username) {
      id
      displayName
      firstName
      lastName
      username
      bio
      avatar {
        id
        url
      }
      socialLinks {
        instagram
      }
      address {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      pronouns
      gender
      shirtSize
      birthday
      skillLevel
      communities {
        edges {
          memberNumber
          node {
            id
            title
            slug
            logo { 
              id
              url(input: {
                transformations: [{
                  aspect_ratio: "1:1"
                  crop: "fill",
                  gravity: "auto"
                }]
              }) 
            }
          }
        }
      }
      inventory {
        edges {
          node {
            ...on Vehicle {
              id
              name
              make
              model
              type
              category
              year
              photo {
                id
                url(input: {
                  transformations: [{
                    aspect_ratio: "1:1"
                    crop: "fill",
                    gravity: "auto"
                  }]
                })
              }
            }
          }
        }
      }
    }
  }
`;

export const ADMIN_CREATE_USER_ACCOUNT = gql`
  mutation CreateUserAccount($input: CreateUserAccountInput!) {
    createUserAccount(input: $input) {
      user {
        id
      }
    }
  }
`;