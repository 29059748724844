import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label, body, subject }) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = `mailto:${mailto}?subject=${subject || ''}&body=${body || ''}`;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default ButtonMailto;