import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { useLocation } from "react-router-dom";
import { analytics } from 'lib/firebase';
import { logEvent } from 'firebase/analytics';

export default applyTo(({ children }) => {
  let location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    logEvent(analytics, "page_view", { page_path });
  }, [location]);
  return children;
}, pipe(
  propTypes({
    children: PropTypes.node,
  }),
  defaultProps({
    children: null,
  }),
  displayName('PageViewAnalytics'),
  memo,
));
