import React, { createContext, useContext, useEffect, useRef } from "react";
import { useMotionValue, useScroll, useSpring, useTransform } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

export const NAV_BAR_HEIGHT = 70;

/**
 * Provides context for managing the navbar state and behavior.
 * Includes methods for showing/hiding the navbar, setting retraction,
 * and locking/unlocking the navbar.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The child components of the NavbarProvider.
 * @returns {React.ReactNode} The NavbarProvider component.
 *
 * @typedef {Object} NavbarContextValue
 * @property {(instant?: boolean) => void} show - Shows the navbar. If `instant` is true, shows immediately without animation.
 * @property {(instant?: boolean) => void} hide - Hides the navbar. If `instant` is true, hides immediately without animation.
 * @property {(value: number, instant?: boolean) => void} setRetraction - Sets the retraction of the navbar. If `instant` is true, sets immediately without animation.
 * @property {() => void} lock - Locks the navbar, preventing any changes to its state.
 * @property {() => void} unlock - Unlocks the navbar, allowing changes to its state.
 * @property {MotionValue<number>} retraction - The current retraction value of the navbar.
 * @property {({direction: 'up' | 'down', scrollY: MotionValue<number>}) => () => void} .revealWhenScrolling Function to handle scroll events for revealing/hiding the navbar based on scroll direction.
 */
const NavbarContext = createContext({});

export function NavbarProvider({ children }) {
  const lockedRef = useRef(false);

  const retraction = useSpring(0, {
    mass: 1,
    damping: 100,
    stiffness: 1000,
  });

  function updateRetraction(value, instant = false) {
    instant ? retraction.jump(value) : retraction.set(value);
  }

  function showNavbar(instant = false) {
    updateRetraction(0, instant);
  }

  function hideNavbar(instant = false) {
    updateRetraction(-NAV_BAR_HEIGHT, instant);
  }

  return (
    <NavbarContext.Provider
      value={{
        show: (instant) => !lockedRef.current && showNavbar(instant),
        hide: (instant) => !lockedRef.current && hideNavbar(instant),
        toggleWhenScrolling: ({ direction, scrollY }) => {
          return scrollY.on('change', (value) => {
            if (lockedRef.current) return;

            const velocity = scrollY.getVelocity();

            if (velocity > 100) {
              direction === 'down' ? showNavbar() : hideNavbar();
            }

            if (velocity < -100) {
              direction === 'down' ? hideNavbar() : showNavbar();
            }
          });
        },
        lock: () => {
          lockedRef.current = true;
        },
        unlock: () => {
          lockedRef.current = false;
        },
        retraction,
      }}>
      {children}
    </NavbarContext.Provider>
  )
}

/**
 * Use this hook to access and manipulate the navbar state and behavior.
 * It provides methods to show/hide the navbar, set its retraction,
 * and lock/unlock its state. Additionally, it provides access to the navbar's
 * current retraction value and a method to handle scroll events for revealing/hiding the navbar.
 *
 * @returns {NavbarContextValue} The navbar context value.
 */
export function useNavbar() {
  return useContext(NavbarContext);
}

export function useTranslateY(translation, springValue) {
  const translateY = useTransform(springValue, (value) => value + translation);
  return useMotionValue(translateY);
}

export function useNavbarRetractor({ container, direction = 'up', showTop = true, enabled = true }) {
  const theme = useTheme();
  const { retraction, toggleWhenScrolling, show, hide, lock, unlock } = useNavbar();
  const { scrollY } = useScroll({
    container: container,
  });
  const isReactNative = window.IS_REACT_NATIVE;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (isDesktop || !enabled || isReactNative) {
      unlock();
      if (showTop || isDesktop) {
        show(true);
      } else {
        hide(true);
      }
      lock();
    } else {
      unlock();
      show(true);
    }

    const clear = toggleWhenScrolling({
      direction,
      scrollY,
    })

    return () => {
      show(true);
      clear();
    }
  }, [isReactNative, scrollY, isDesktop, show, hide, showTop, lock, unlock, toggleWhenScrolling, direction, enabled]);

  return retraction;
}