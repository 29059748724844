import React, { memo } from 'react';
import { CircularProgress } from '@mui/material';
import { pipe, applyTo } from 'ramda';

import FlexCenter from 'components/FlexCenter';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(() => {
  return (
    <FlexCenter>
      <CircularProgress />
    </FlexCenter>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('Progress'),
  memo,
));
