import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

import config from 'config';
import useCustomAuth0 from 'hooks/useCustomAuth0';
import { useEffect, useState } from 'react';

/**
 * Public Client
 */

export const publicClient = new ApolloClient({
  uri: `${config.API_HOST}/public/graphql`,
  cache: new InMemoryCache(),
});

/**
 * Private Client
 */

/**
 * Extend the basic ApolloProvider for the private client
 * 
 * - abstracts our public / private implementations
 * - handles setting of access token's
 */
const RallyApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useCustomAuth0();
  const [client, setClient] = useState();

  const httpLink = createHttpLink({
    uri: `${config.API_HOST}/graphql`,
  });

  const authLink = setContext(async (_, { headers, ...context }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    const token = await getAccessTokenSilently();
    localStorage.setItem('token', token);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...context,
    };
  });

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache({
        typePolicies: {
          User: {
            fields: {
              community: {
                merge(existing = {}, incoming) {
                  return { ...existing, ...incoming };
                }
              }
            }
          },
          Community: {
            fields: {
              members: {
                keyArgs: false,
                merge(existing = {}, incoming) {
                  return {
                    ...existing,
                    ...incoming,
                    edges: (existing.edges || []).concat(incoming.edges)
                  }
                }
              },
            }
          },
          Channel: {
            fields: {
              members: {
                keyArgs: false,
                merge(existing = {}, incoming) {
                  return {
                    ...existing,
                    ...incoming,
                    edges: (existing.edges || []).concat(incoming.edges)
                  }
                }
              },
            }
          },
        }
      });
      // await persistCache({
      //   cache,
      //   storage: new LocalStorageWrapper(window.localStorage),
      // });
      setClient(
        new ApolloClient({
          link: authLink.concat(httpLink),
          cache
        })
      );
    }

    init().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!client) return null;

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
};

export default RallyApolloProvider;