import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ width = 18, height = 18, color = "black" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1.52742" y1="0.989182" x2="19.2051" y2="18.6669" stroke={color} strokeOpacity="0.5" strokeWidth="2" />
      <line y1="-1" x2="25" y2="-1" transform="matrix(-0.707107 0.707107 0.707107 0.707107 19.5 1.69629)" stroke={color} strokeOpacity="0.5" strokeWidth="2" />
    </svg>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName('CloseIcon'),
  memo,
));
